import { createSlice } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';

const countrySelectorSlice = createSlice({
    name: 'countrySelector',
    initialState: {
        isoName: "US"
    },
    reducers: {
        setCountry(state, action) {
            // console.log('setCountry =>',action)
            state.isoName = action.payload
            navigate(`/${action.payload}/`);
        },
    }
})

export const { setCountry } = countrySelectorSlice.actions;
export default countrySelectorSlice.reducer;