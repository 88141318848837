import { createSlice } from '@reduxjs/toolkit'

import allowedCrypto from '../../data/allowedCrypto.json';

export const enabledChains = allowedCrypto.chains.filter(({enabled}) => enabled);
export const enabledTokens = allowedCrypto.tokens.filter(({enabled}) => enabled);

const tokenSelectorSlice = createSlice({
    name: 'token',
    initialState: {
        token: enabledTokens[0],
        chain: allowedCrypto.chains.find(o => o.chainId === enabledTokens[0].chainId)
    },
    reducers: {
        setToken(state, action) {
            console.log('tokenSelectorSlice.setToken =>',action);
            let foundToken = allowedCrypto.tokens.find(o => o.ticker === action.payload);
            state.token = foundToken;
            if (foundToken) {
                state.chain = allowedCrypto.chains.find(o => o.chainId === foundToken.chainId);
            } else {
                state.chain = undefined;
            }
        },
    }
})

export const { setToken } = tokenSelectorSlice.actions
export default tokenSelectorSlice.reducer