import { configureChains, createClient } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { LedgerConnector } from 'wagmi/connectors/ledger';

export const chains = [ bsc, bscTestnet ];

const bscRpcOfficialDocs = [
    'https://bsc-dataseed.binance.org',
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc.nodereal.io'
];

const bscTestnetRpcOfficialDocs = [
    'https://data-seed-prebsc-1-s3.binance.org:8545',
    'https://data-seed-prebsc-2-s3.binance.org:8545',
    'https://data-seed-prebsc-1-s1.binance.org:8545',
    'https://data-seed-prebsc-1-s1.binance.org:8545'
];

// Wagmi client
const { provider } = configureChains(
    chains, 
    [
        jsonRpcProvider({
            rpc: (chain) => ({
                http: (chain.id === bsc.id) ? bscRpcOfficialDocs[0] : bscTestnetRpcOfficialDocs[0]
            })
        }),
        jsonRpcProvider({
            rpc: (chain) => ({
                http: (chain.id === bsc.id) ? bscRpcOfficialDocs[1] : bscTestnetRpcOfficialDocs[1]
            })
        }),
        jsonRpcProvider({
            rpc: (chain) => ({
                http: (chain.id === bsc.id) ? bscRpcOfficialDocs[2] : bscTestnetRpcOfficialDocs[2]
            })
        }),
        jsonRpcProvider({
            rpc: (chain) => ({
                http: (chain.id === bsc.id) ? bscRpcOfficialDocs[3] : bscTestnetRpcOfficialDocs[3]
            })
        }),
        publicProvider()
    ]
);

export const wagmiClient = createClient({
    autoConnect: false,
    connectors: [
        new LedgerConnector({ 
            chains,
            options: {
                enableDebugLogs: true,
            },
        }),
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'Crypto2Gift',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
                version: '2',
                projectId: '97d4f2cdeacc7423c9e74ca2568d8d13'
            },
        }),
    ],
    provider,
});

// console.log('wc project id =>',`${'97d4f2cdeacc7423c9e74ca2568d8d13'}`);

// Web3Modal Ethereum Client
// export const ethereumClient = new EthereumClient(wagmiClient, chains);
