import { createSlice } from '@reduxjs/toolkit'

// We keep this here so we can reuse it other places, like the resetState action
const initState = {
    address: null
}

const walletSlice = createSlice({
    name: 'wallet',
    initialState: initState,
    reducers: {
        setAddress(state, address) {
            state.address = address.payload
        },
        resetState(state) {
            state.address = initState.address
        }
    }
})

export const { setAddress, resetState } = walletSlice.actions
export default walletSlice.reducer