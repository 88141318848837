import { createSlice } from '@reduxjs/toolkit'

const initialSliceState = {
    currentStage: 'V',
    validationStageOk: false,
    offerRequest: {
        productId: null,
        senderValue: null,
        recipientCurrency: null,
        buyerWallet: null,
        cryptoToken: null,
        recipient: {
            email: null,
            message: null
        },
        browserTime: null,
        debug: false
    },
    hasValidOffer: false,
    offerReply: {
        response: {
            offerDbHash: null,
            offerId: null,
            productId: null,
            productValue: null,
            buyerWallet: null,
            recipient: {
                email: null,
                message: null
            },
            financial: {
                recipient_currency: null,
                xrate: null,
                quote_currency: null,
                quote_product_value: null,
                quote_c2g_processing: null,
                quote_gas: null,
                quote_discount: null,
                quote_total: null
            },
            expiry: null
        },
        error: {
            num: null,
            message: null
        },
        debug: null,
        ts: null
    },
    hasWeb3Tx: false,
    web3Tx: {

    },
    hasReceipt: false,
    receipt: {

    }
};

const orderSlice = createSlice({
    name: 'order',
    initialState: initialSliceState,
    reducers: {
        setStage(state, action) {
            console.log('orderSlice.setStage =>',action.payload);
            state.currentStage = action.payload;
        },
        setValidationOk(state, action) {
            state.validationStageOk = action.payload;
        },
        setForNewWorkflow(state, action) {
            console.log(action);
            state.currentStage = 'V';
            state.offerRequest = action.payload;
        },
        setBuyerWalletAddress(state, action) {
            state.offerRequest.buyerWallet = action.payload;
        },
        setRecipientEmail(state, action) {
            state.offerRequest.recipient.email = action.payload;
        },
        setRecipientMsg(state, action) {
            state.offerRequest.recipient.message = action.payload.substring(0,255);
        },
        setOfferRequestToken(state, action) {
            state.offerRequest.cryptoToken = action.payload;
        },
        setBrowserTime(state) {
            state.offerRequest.browserTime = Date.now();
        },
        resetOrderState(state, action) {
            state = {...initialSliceState};
            return state;
        },
    }
});

export const { 
    setStage,
    setValidationOk,
    setForNewWorkflow,
    setBuyerWalletAddress,
    setRecipientEmail,
    setRecipientMsg,
    setOfferRequestToken,
    setBrowserTime,
    resetOrderState,
} = orderSlice.actions;

export default orderSlice.reducer;