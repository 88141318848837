// From GatsbyJS example "Using Redux"

import React from "react"
import { Provider } from "react-redux"

import store from "./src/state/store"

import { WagmiConfig } from "wagmi";
import { wagmiClient } from "./src/services/blockchain";

var wrapper = ({ element }) => {
    return (
        <>
            <Provider store={store}>
                <WagmiConfig client={wagmiClient}>
                    {element}
                </WagmiConfig>
            </Provider>
        </>
    )
}

export default wrapper