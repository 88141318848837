import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'

import orderReducer from '../components/Ordering/orderSlice'
import tokenSelectorReducer from '../components/TokenSelector/tokenSelectorSlice'
import countrySelectorReducer from '../components/CountrySelector/countrySelectorSlice'
import walletReducer from '../components/walletConnect/wcSlice'
import { azureTestApi } from '../services/azureTest'
import { c2gApi } from '../services/c2gApi'

const store = configureStore({
    reducer: {
        order: orderReducer,
        tokenSelector: tokenSelectorReducer,
        countrySelector: countrySelectorReducer,
        wallet: walletReducer,
        [azureTestApi.reducerPath]: azureTestApi.reducer,
        [c2gApi.reducerPath]: c2gApi.reducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(azureTestApi.middleware).concat(c2gApi.middleware),
})

setupListeners(store.dispatch)

export default store